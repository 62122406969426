<template>
    <b-container>
        <b-row class="text-center">
            <b-col cols="12">
                <h1>Des idées de packs</h1>
                <p class="mt-5">
                    Créés spécialement pour vous.<br />
                    À offrir ou à s'offrir... tout est possible, je serai
                    ravie d'en <b-link to="/contact">discuter</b-link> avec vous.
                </p>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="6">
                <b-card title="Pack Allaitement" class="text-center">
                    <b-card-text class="mt-4">
                        Préparation à l'allaitement <br />
                        + <br />
                        Accompagnement à l'allaitement
                    </b-card-text>
                    <b-card-footer>120€*</b-card-footer>
                </b-card>
            </b-col>
            <b-col cols="12" md="6" class="mt-5 mt-md-0">
                <b-card title="Pack Koala" class="text-center">
                    <b-card-text class="mt-4">
                        Atelier portage prénatal <br />
                        + <br />
                        Atelier portage postnatal
                    </b-card-text>
                    <b-card-footer>100€*</b-card-footer>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" lg="6">
                <b-card title="Pack Bienvenue" class="text-center">
                    <b-card-text class="mt-4">
                        Thalasso Thérapeutique Bain Bébé© <br />
                        + <br />
                        Atelier massage bébé OU Atelier réflexologie bébé émotionnelle®
                    </b-card-text>
                    <b-card-footer>150€*</b-card-footer>
                </b-card>
            </b-col>
            <b-col cols="12" lg="6" class="mt-5 mt-md-0">
                <b-card title="Pack Parenthèse de Douceur" class="text-center">
                    <b-card-text class="mt-4">
                        Thalasso Thérapeutique Bain Bébé©<br />
                        + <br />
                        Massage japonais du visage "Kobido"<br />
                        <br />
                    </b-card-text>
                    <b-card-footer>160€*</b-card-footer>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" lg="6" offset-lg="3">
                <b-card title="Pack Souvenir" class="text-center">
                    <b-card-text class="mt-4">
                        Thalasso Thérapeutique Bain Bébé© <br />
                        + <br />
                        Reportage photo avec Mathilde de <a href="https://graindeclic-photographie.com/"
                            style="font-weight: bold;">Grain de Clic
                            Photographie</a>
                    </b-card-text>
                    <b-card-footer>À partir de 320€* et selon les disponibilités</b-card-footer>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <em>* des frais peuvent s'ajouter en fonction de la localisation de votre domicile.</em>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { BCard, BCardText, BCardFooter, BCardGroup, BListGroup, BListGroupItem } from 'bootstrap-vue';
export default {
    name: 'Packs',
    components: {
        BCard,
        BCardText,
        BCardFooter,
        BCardGroup,
        BListGroup,
        BListGroupItem,
    },
};
</script>

<style scoped>
h4 {
    color: var(--secondary);
}

em {
    font-size: 12px;
}

.row {
    margin-top: 50px;
}

.card {
    height: 100%;
}

.card-footer {
    font-weight: bold;
    color: #fff;
    background-color: var(--secondary);
}
</style>